import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC4_g5xNgwGD7-VjvXe8Aij8EupBMw4tjA",
    authDomain: "websvastsys.firebaseapp.com",
    projectId: "websvastsys",
    storageBucket: "websvastsys.appspot.com",
    messagingSenderId: "144412647865",
    appId: "1:144412647865:web:22e3511e11cc9a32909668",
    measurementId: "G-YMX7S6386S"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, collection, addDoc };