import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './NavBar/Navbar';
import Home from './Home/Home';
import Services from './Services/Services';
import Industries from './Industries/Industries';
import HireUs from './HireUs/HireUs';
import HappyClient from './HappyClient/HappyClient';
import Footer from './Footer/Footer';
import ProjectJourney from './ProjectJourney/ProjectJourney';
import Technology from './Technology/Technology';
import ContactUs from './ContactUS/ContactUs';
// import firebase from 'firebase/compat/app';


function App() {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/Contact" element={<ContactUs />} />
          <Route
            path="/"
            element={
              <>
                <div className='homeBg'><Home /></div>
                <div id="services"><Services /></div>
                <div id="industries"><Industries /></div>
                <div id="hireus"><HireUs /></div>
                <div id="happyclient"><HappyClient /></div>
                <div id="projectjourney"><ProjectJourney /></div>
                <div id="technology"><Technology /></div>
                <div id="footer"><Footer /></div>
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
