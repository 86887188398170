import React from "react";
import './Industries.css'
import styled, { css } from 'styled-components'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import curve3 from '../Assets/curve-3.png'
import house from '../Assets/house.png'
import travel from '../Assets/travel.png'
import education from '../Assets/education.png'
import distribution from '../Assets/distribution.png'
import social from '../Assets/social.png'
import healthcare from '../Assets/healthcare.png'
import agriculture from '../Assets/agriculture.png'
import banking from '../Assets/banking.png'
import food from '../Assets/food.png'
import sports from '../Assets/sports.png'


const Industries = () => {
     const TexhnologyRegisterContainer = styled.div`
    width: 360px;
    @media (min-width:0px) and (max-width:360px) {
         width: 50%;
    }
    `;

     return (
          <><div class="portfolio-bg-curve">
               <img src={curve3} alt="" />
          </div>
               <div className="container">
                    <div className="industries-title">
                         <p>Industries We  Serve</p>
                         <p></p>
                    </div>
                    <div className="industries_items">
                         <TexhnologyRegisterContainer className="industries_single_items">
                              <div className="industries_icon">
                                   <img src={house} alt="" />
                              </div>
                              <div className="industries_item_title">
                                   <h5>Housing & Real Estate</h5>
                              </div>
                         </TexhnologyRegisterContainer>
                         <TexhnologyRegisterContainer className="industries_single_items">
                              <div className="industries_icon">
                                   <img src={travel} alt="" />
                              </div>
                              <div className="industries_item_title">
                                   <h5>Travel & Hospitality</h5>
                              </div>
                         </TexhnologyRegisterContainer>
                         <TexhnologyRegisterContainer className="industries_single_items">
                              <div className="industries_icon">
                                   <img src={education} alt="" />
                              </div>
                              <div className="industries_item_title">
                                   <h5>Education & e-learning</h5>
                              </div>
                         </TexhnologyRegisterContainer>
                         <TexhnologyRegisterContainer className="industries_single_items">
                              <div className="industries_icon">
                                   <img src={distribution} alt="" />
                              </div>
                              <div className="industries_item_title">
                                   <h5>Logistics & Distribution</h5>
                              </div>
                         </TexhnologyRegisterContainer>
                         <TexhnologyRegisterContainer className="industries_single_items">
                              <div className="industries_icon">
                                   <img src={social} alt="" />
                              </div>
                              <div className="industries_item_title">
                                   <h5>Social Networking</h5>
                              </div>
                         </TexhnologyRegisterContainer>
                         <TexhnologyRegisterContainer className="industries_single_items">
                              <div className="industries_icon">
                                   <img src={healthcare} alt="" />
                              </div>
                              <div className="industries_item_title">
                                   <h5>Healthcare & Fitness</h5>
                              </div>
                         </TexhnologyRegisterContainer>
                         <TexhnologyRegisterContainer className="industries_single_items">
                              <div className="industries_icon">
                                   <img src={agriculture} alt="" />
                              </div>
                              <div className="industries_item_title">
                                   <h5>Agriculture</h5>
                              </div>
                         </TexhnologyRegisterContainer>
                         <TexhnologyRegisterContainer className="industries_single_items">
                              <div className="industries_icon">
                                   <img src={banking} alt="" />
                              </div>
                              <div className="industries_item_title">
                                   <h5>Banking Services</h5>
                              </div>
                         </TexhnologyRegisterContainer>
                         <TexhnologyRegisterContainer className="industries_single_items">
                              <div className="industries_icon">
                                   <img src={food} alt="" />
                              </div>
                              <div className="industries_item_title">
                                   <h5>Food & Restaurant</h5>
                              </div>
                         </TexhnologyRegisterContainer>
                         <TexhnologyRegisterContainer className="industries_single_items">
                              <div className="industries_icon">
                                   <img src={sports} alt="" />
                              </div>
                              <div className="industries_item_title">
                                   <h5>Sports</h5>
                              </div>
                         </TexhnologyRegisterContainer>
                    </div>
               </div></>
     );
}

export default Industries;