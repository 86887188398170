// import React, { useState } from "react";





// const Technology = () => {

//     const [menu, setMenu] = useState("Mobile");

//     const Mobile = () => {
//         return (
//             <div className="content">
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Android} alt="Android Studio" /></div>
//                     <p>Android Studio</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={IOS} alt="iOS" /></div>
//                     <p>iOS</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={ReactNative} alt="React Native" /></div>
//                     <p>React Native</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Flutter} alt="Flutter" /></div>
//                     <p>Flutter</p>
//                 </div>
//             </div>
//         )
//     }
//     const Frontend = () => {
//         return (
//             <div className="content">
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Angular} alt="Angular JS" /></div>
//                     <p>Angular JS</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Reactjs} alt="React JS" /></div>
//                     <p>React JS</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Html} alt="Html" /></div>
//                     <p>HTML</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Codeigniter} alt="Codeigniter" /></div>
//                     <p>CodeIgniter</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Laravel} alt="Laravel" /></div>
//                     <p>Laravel</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={VueJs} alt="VueJs" /></div>
//                     <p>VueJs</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Wordpress} alt="Wordpress" /></div>
//                     <p>WordPress</p>
//                 </div>
//             </div>
//         )
//     }
//     const Backend = () => {
//         return (
//             <div className="content">
//                 <div className="item-tech">
//                     <div className="techImg"><img src={NodeJs} alt="NodeJs" /></div>
//                     <p>NodeJs</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Python} alt="Python" /></div>
//                     <p>Python</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Flask} alt="Flask" /></div>
//                     <p>Flask</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Django} alt="Django" /></div>
//                     <p>DJango</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Php} alt="Php" /></div>
//                     <p>PHP</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Nest} alt="Nest" /></div>
//                     <p>Nest</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Java} alt="Java" /></div>
//                     <p>Java</p>
//                 </div>
//             </div>
//         )
//     }
//     const Design = () => {
//         return (
//             <div className="content">
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Photoshop} alt="Photoshop" /></div>
//                     <p>Photoshop</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Illustrator} alt="Illustrator" /></div>
//                     <p>Illustrator</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Figma} alt="Figma" /></div>
//                     <p>Figma</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={AfterEffect} alt="AfterEffect" /></div>
//                     <p>AfterEffect</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={IID} alt="ID" /></div>
//                     <p>ID</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={LRR} alt="Lr" /></div>
//                     <p>LRR</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={XDD} alt="Xd" /></div>
//                     <p>XDD</p>
//                 </div>
//             </div>
//         )
//     }
//     const Database = () => {
//         return (
//             <div className="content">
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Firebase} alt="Firebase" /></div>
//                     <p>Firebase</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={mongoDB} alt="mongoDB" /></div>
//                     <p>mongoDB</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Postgre} alt="PostgreSQL" /></div>
//                     <p>PostgreSQL</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={MySQL} alt="MySQL" /></div>
//                     <p>MySQL</p>
//                 </div>
//             </div>
//         )
//     }
//     const Infrastructure = () => {
//         return (
//             <div className="content">
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Aws} alt="Aws" /></div>
//                     <p>AWS</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={GoogleCloud} alt="Google Cloud" /></div>
//                     <p>Google Cloud</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Azure} alt="Azure" /></div>
//                     <p>Azure</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Gradle} alt="Gradle" /></div>
//                     <p>Gradle</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={DigitalOcean} alt="Digital Ocean" /></div>
//                     <p>Digital Ocean</p>
//                 </div>
//                 <div className="item-tech">
//                     <div className="techImg"><img src={Se} alt="Selenium" /></div>
//                     <p>Selenium</p>
//                 </div>
//             </div>
//         )
//     }


//     const renderMenuContent = () => {
//         switch (menu) {
//             case "Mobile":
//                 return <Mobile />;
//             case "Frontend":
//                 return <Frontend />; // Replace with actual frontend content
//             case "Backend":
//                 return <Backend />; // Replace with actual backend content
//             case "Design":
//                 return <Design />; // Replace with actual design content
//             case "Database":
//                 return <Database />; // Replace with actual database content
//             case "Infrastructure":
//                 return <Infrastructure />; // Replace with actual infrastructure content
//             default:
//                 return null;
//         }
//     };

//     return (
//         <>
//             <div class="portfolio-bg-curve">
//                 <img src={curve3} alt="" />
//             </div>
//             <div className="container te-main">
//                 <div className="tech-head fadeInLeft">
//                     <h3>Technologies We<br />Work With</h3>
//                 </div>
//                 <div>
//                     <div className="mytit">
//                         <div className="title-tech">
//                             <p onClick={() => { setMenu("Mobile") }} style={{ color: menu === "Mobile" ? "#086ad8" : "#686666" }}  >Mobile{menu === "Mobile" ? <hr /> : <></>}</p>
//                             <p onClick={() => { setMenu("Frontend") }} style={{ color: menu === "Frontend" ? "#086ad8" : "#686666" }} >Frontend{menu === "Frontend" ? <hr /> : <></>}</p>
//                             <p onClick={() => { setMenu("Backend") }} style={{ color: menu === "Backend" ? "#086ad8" : "#686666" }} >Backend{menu === "Backend" ? <hr /> : <></>}</p>
//                             <p onClick={() => { setMenu("Design") }} style={{ color: menu === "Design" ? "#086ad8" : "#686666" }} >Design{menu === "Design" ? <hr /> : <></>}</p>
//                             <p onClick={() => { setMenu("Database") }} style={{ color: menu === "Database" ? "#086ad8" : "#686666" }} >Database{menu === "Database" ? <hr /> : <></>}</p>
//                             <p onClick={() => { setMenu("Infrastructure") }} style={{ color: menu === "Infrastructure" ? "#086ad8" : "#686666" }} >Infrastructure{menu === "Infrastructure" ? <hr /> : <></>}</p>
//                         </div>
//                         {renderMenuContent()}

//                     </div>
//                 </div>
//             </div></>
//     )
// }

// export default Technology;




import React, { useState } from "react";
import './Technology.css'
import { Card, CardContent, CardMedia, Typography, Container, Grid, Tabs, Tab } from '@mui/material';
import curve3 from '../Assets/curve-3.png';
import Android from '../Assets/mobile/android.png'
import IOS from '../Assets/mobile/ios.png'
import ReactNative from '../Assets/mobile/reactNative.png'
import Flutter from '../Assets/mobile/flutter.png'
import Reactjs from '../Assets/FrontEnd/reactjs.png'
import Angular from '../Assets/FrontEnd/angular.png'
import AspNet from '../Assets/FrontEnd/aspNet.png'
import Codeigniter from '../Assets/FrontEnd/codeigniter.png'
import Django from '../Assets/FrontEnd/django.png'
import Html from '../Assets/FrontEnd/html.png'
import Jsfram from '../Assets/FrontEnd/jsfram.png'
import Laravel from '../Assets/FrontEnd/laravel.png'
import VueJs from '../Assets/FrontEnd/vueJs.png'
import Wordpress from '../Assets/FrontEnd/wordpress.png'

import NodeJs from '../Assets/BackEnd/nodejs.png'
import Flask from '../Assets/BackEnd/flask.png'
import Python from '../Assets/BackEnd/python.png'
import Nest from '../Assets/BackEnd/nest.png'
import Java from '../Assets/BackEnd/java.png'
import Php from '../Assets/BackEnd/php.png'

import AfterEffect from '../Assets/Designs/AfterEffect.png'
import Figma from '../Assets/Designs/Figma.png'
import Illustrator from '../Assets/Designs/Illustrator.png'
import Photoshop from '../Assets/Designs/Photoshop.png'
import IID from '../Assets/Designs/Id.png'
import LRR from '../Assets/Designs/Lr.png'
import XDD from '../Assets/Designs/Xd.png'

import Firebase from '../Assets/Database/Firebase.png'
import mongoDB from '../Assets/Database/mongoDB.png'
import MySQL from '../Assets/Database/MySQL.png'
import Postgre from '../Assets/Database/Postgre.png'

import Aws from '../Assets/Database/Aws.png'
import DigitalOcean from '../Assets/Database/DigitalOcean.png'
import GoogleCloud from '../Assets/Database/GoogleCloud.png'
import Azure from '../Assets/Database/azure.png'
import Gradle from '../Assets/Database/Gradle.png'
import Se from '../Assets/Database/Se.png'

const Technology = () => {
    const [menu, setMenu] = useState("Mobile");

    const techItems = {
        Mobile: [
            { src: Android, alt: "Android Studio", title: "Android Studio" },
            { src: IOS, alt: "iOS", title: "iOS" },
            { src: ReactNative, alt: "React Native", title: "React Native" },
            { src: Flutter, alt: "Flutter", title: "Flutter" }
        ],
        Frontend: [
            { src: Reactjs, alt: "React JS", title: "React JS" },
            { src: Angular, alt: "Angular JS", title: "Angular JS" },
            { src: Html, alt: "HTML", title: "HTML" },
            { src: Codeigniter, alt: "CodeIgniter", title: "CodeIgniter" },
            { src: Laravel, alt: "Laravel", title: "Laravel" },
            { src: VueJs, alt: "VueJS", title: "VueJS" },
            { src: Wordpress, alt: "WordPress", title: "WordPress" }
        ],
        Backend: [
            { src: NodeJs, alt: "Node.js", title: "Node.js" },
            { src: Python, alt: "Python", title: "Python" },
            { src: Flask, alt: "Flask", title: "Flask" },
            { src: Django, alt: "Django", title: "Django" },
            { src: Php, alt: "PHP", title: "PHP" },
            { src: Nest, alt: "Nest", title: "Nest" },
            { src: Java, alt: "Java", title: "Java" }
        ],
        Design: [
            { src: Photoshop, alt: "Photoshop", title: "Photoshop" },
            { src: Illustrator, alt: "Illustrator", title: "Illustrator" },
            { src: Figma, alt: "Figma", title: "Figma" },
            { src: AfterEffect, alt: "After Effects", title: "After Effects" },
            { src: IID, alt: "InDesign", title: "InDesign" },
            { src: LRR, alt: "Lightroom", title: "Lightroom" },
            { src: XDD, alt: "XD", title: "XD" }
        ],
        Database: [
            { src: Firebase, alt: "Firebase", title: "Firebase" },
            { src: mongoDB, alt: "MongoDB", title: "MongoDB" },
            { src: Postgre, alt: "PostgreSQL", title: "PostgreSQL" },
            { src: MySQL, alt: "MySQL", title: "MySQL" }
        ],
        Infrastructure: [
            { src: Aws, alt: "AWS", title: "AWS" },
            { src: GoogleCloud, alt: "Google Cloud", title: "Google Cloud" },
            { src: Azure, alt: "Azure", title: "Azure" },
            { src: Gradle, alt: "Gradle", title: "Gradle" },
            { src: DigitalOcean, alt: "Digital Ocean", title: "Digital Ocean" },
            { src: Se, alt: "Selenium", title: "Selenium" }
        ]
    };

    const handleTabChange = (event, newValue) => {
        setMenu(newValue);
    };

    return (
        <>
            {/* <div className="portfolio-bg-curve">
                <img src={curve3} alt="Background Curve" />
            </div> */}
            <Container>
                <Typography variant="h3" component="h3" align="center" gutterBottom>
                    Technologies We Work With
                </Typography>
                <Tabs
                    value={menu}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    className="tech-tabs"
                >
                    <Tab value="Mobile" label="Mobile" />
                    <Tab value="Frontend" label="Frontend" />
                    <Tab value="Backend" label="Backend" />
                    <Tab value="Design" label="Design" />
                    <Tab value="Database" label="Database" />
                    <Tab value="Infrastructure" label="Infrastructure" />
                </Tabs>


                <div style={{ margin: '50px 0' }}>
                    <Grid container spacing={2} justifyContent="center">
                        {techItems[menu].map((item, index) => (
                            <Grid item xs={6} sm={6} md={4} lg={3} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Card style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '10px', height: '150px', width: '150px' }}>
                                    <CardMedia
                                        component="img"
                                        image={item.src}
                                        alt={item.alt}
                                        style={{ width: '60px', height: '60px', objectFit: 'contain' }}
                                    />
                                    <CardContent style={{ padding: 0, textAlign: 'center', marginTop: '10px' }}>
                                        <Typography variant="body2">
                                            {item.title}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Container>
        </>
    );
}

export default Technology;
